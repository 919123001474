<div class="item-list-component" [ngClass]="{'dx-rtl': rtlEnabled}" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
    <dx-popup
          width="90%"
          [showTitle]="true"
          title="{{ title }}"
          [dragEnabled]="false"
          [closeOnOutsideClick]="false"
          [rtlEnabled]="rtlEnabled"
          [(visible)]="opened"
          (onHiding)="opened = false;">
      <dx-scroll-view width="100%" height="100%">
        <div class="au-dx-popup-padder">
            <p>{{ message }}</p>
            <button type="button" class="btn btn-outline" (click)="opened = false;">{{ 'SHARED.CANCEL' | translate }}</button>
            <button type="button" class="btn btn-primary" [disabled]="moveLotsConfirmationButtonDisabled" (click)="opened = false; close.emit(true);">{{ 'MOVE_LOTS.TITLE' | translate }}</button>
        </div>
          </dx-scroll-view>
      </dx-popup>
  </div>
  