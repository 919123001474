<div class="item-list-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <div class="grid-buttons">
    <dx-button icon="add" hint="{{ 'SHARED.ADD' | translate }}"
               (onClick)="add()"></dx-button>
    <dx-button icon="print" hint="{{ 'SHARED.PRINT' | translate }}"
               (onClick)="print()"></dx-button>
  </div>
  <br />
  <dx-data-grid #auctionBuyersGrid id="printSection"
                [allowColumnReordering]="true"
                [allowColumnResizing]="true"
                [columnAutoWidth]="true"
                [dataSource]="customDataSource"
                [hoverStateEnabled]="true"
                [rtlEnabled]="rtlEnabled"
                [showBorders]="true"
                [rowAlternationEnabled]="true"
                (onRowClick)="edit($event)"
                (onInitialized)="initColumn($event)"
                (onExporting)="onExporting($event)">
    <dxo-export [enabled]="true"></dxo-export>
    <dxo-column-chooser [enabled]="true" mode="select"></dxo-column-chooser>
    <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
    <dxo-filter-panel [visible]="true"></dxo-filter-panel>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-search-panel [visible]="true"></dxo-search-panel>
    <dxo-selection mode="single"></dxo-selection>
    <dxo-state-storing [enabled]="true" type="localStorage" storageKey="storage_buyers"></dxo-state-storing>
    <dxo-remote-operations [paging]="true"
                           [filtering]="true"
                           [sorting]="true"></dxo-remote-operations>
    <dxo-pager [allowedPageSizes]="[5, 10, 20, 30, 40, 50, 100, 200]"
               [showInfo]="true"
               [showNavigationButtons]="true"
               [showPageSizeSelector]="true"
               [visible]="true"
               infoText="{2} {{ 'SHARED.PAGINATION_TEXT_PLACEHOLDER' | translate | slice:2 }}">
    </dxo-pager>
    <dxi-column [editorOptions]="{ showClearButton: true }" dataType="boolean" dataField="isActiveOnAuctionCluster" caption="{{ 'SHARED.IS_ACTIVE' | translate }}">
    </dxi-column>
    <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="name" caption="{{ 'SHARED.NAME' | translate }}">
    </dxi-column>
    <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="buyerNumber" caption="{{ 'SHARED.NUMBER' | translate }}">
    </dxi-column>
    <dxi-column [editorOptions]="{ showClearButton: true }" cellTemplate="limitCell" dataField="creditLimit" caption="{{ 'BUYER.LIMIT' | translate }}">
    </dxi-column>
    <dxi-column [editorOptions]="{ showClearButton: true }" cellTemplate="remainingCell" dataField="remainingLimit" caption="{{ 'BUYER.REMAINING_LIMIT' | translate }}">
    </dxi-column>
    <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="clocks" [allowSearch]="false" caption="{{ 'SHARED.AUCTION' | translate }}">
    </dxi-column>
    <dxi-column [editorOptions]="{ showClearButton: true }" dataType="number" dataField="userLicenseCount" caption="{{ 'BUYER.USER_LICENCES' | translate }}" [visible]="false">
    </dxi-column>
    <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="email" caption="{{ 'SHARED.EMAIL' | translate }}" [visible]="false">
    </dxi-column>
    <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="telNumber" caption="{{ 'SHARED.TEL_NUMBER' | translate }}">
    </dxi-column>
    <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="vat" caption="{{ 'SHARED.VAT' | translate }}" [visible]="false">
    </dxi-column>
    <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="address1" caption="{{ 'SHARED.ADDRESS1' | translate }}">
    </dxi-column>
    <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="zip" caption="{{ 'SHARED.ZIP' | translate }}">
    </dxi-column>
    <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="city" caption="{{ 'SHARED.CITY' | translate }}">
    </dxi-column>
    <dxi-column [editorOptions]="{ showClearButton: true }" dataType="string" dataField="country" caption="{{ 'SHARED.COUNTRY' | translate }}">
    </dxi-column>
    <dxi-column type="buttons" caption="..." [allowHiding]="false">
      <dxi-button icon="edit" hint="{{ 'SHARED.EDIT' | translate }}"
                  [onClick]="edit"></dxi-button>
      <dxi-button icon="trash" hint="{{ 'SHARED.DELETE' | translate }}"
                [onClick]="deleteItem"></dxi-button>
      <dxi-button icon="remove" hint="{{ 'BUYER.DEACTIVATE_BUYER' | translate }}"
                  [onClick]="deactivateBuyer"
                  [visible]="calculateDisabledDisplayValue"></dxi-button>
      <dxi-button icon="check" hint="{{ 'BUYER.ACTIVATE_BUYER' | translate }}"
                  [onClick]="activateBuyer"
                  [visible]="calculateEnabledDisplayValue"></dxi-button>
    </dxi-column>

    <div *dxTemplate="let data of 'remainingCell'">
      {{ data.displayValue | format:2 }}
    </div>

    <div *dxTemplate="let data of 'limitCell'">
      {{ data.displayValue | format:2 }}
    </div>
  </dx-data-grid>
</div>

<buyer-component #details (onClosed)="onDetailsComponentClosed($event)"></buyer-component>
<confirmation-component #confirmationDelete title="{{ 'BUYER.DELETE_BUYER' | translate }}"
                        message="{{ 'BUYER.DELETE_BUYER_MESSAGE' | translate }}"
                        buttonCloseText="{{ 'SHARED.DELETE' | translate }}" cancellable="true" (close)="deleteSelected();"></confirmation-component>
<confirmation-component #confirmationDeactivate title="{{ 'BUYER.DEACTIVATE_BUYER' | translate }}"
                        message="{{ 'BUYER.DEACTIVATE_BUYER_MESSAGE' | translate }}" buttonCloseText="{{ 'BUYER.DEACTIVATE' | translate }}" cancellable="true"
                        (close)="enableDisableBuyer();"></confirmation-component>
