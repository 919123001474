import { Component, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import * as _moment from 'moment';
import { Subscription } from 'rxjs';

// services
import { LanguageService } from '../../../shared/services/language.service';

@Component({
  selector: 'move-lots-confirmation-component',
  templateUrl: 'move-lots-confirmation.component.html',
  providers: []
})
export class MoveLotsConfirmationComponent implements OnDestroy {

  @Input('title') title: string;
  @Input('message') message: string;
  @Input('moveLotsConfirmationButtonDisabled') moveLotsConfirmationButtonDisabled: boolean;

  @Output() close = new EventEmitter<boolean>();

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  constructor(
    private languageService: LanguageService
  ) {
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  opened: boolean;
}
