export class Clock {
  clockId: number;
  auctionId: number;
  catalogId: number;
  auctionClusterId: number;
  name: string;
  description: string;
  layoutData: string;
  isDemo: boolean;
  isStarted: boolean;
  isDeleted: boolean;
  activeCatalogId: number;
  nextSale: NextSale;
  mode: ClockMode;
  transactions: ClockTransactions;
  audio: ClockAudio;
  network: ClockNetwork;
  settings: ClockSettings;
  video: ClockVideo;
  useWebSocketOnly: boolean;
  playerBufferSize: number;
  recorderBufferSize: number;
  sampleRate: number;
  opusSamplingRate: number;
  frameDuration: number;

  clockManagamentPermission = false;
  // tslint:disable:no-use-before-declare
  constructor() {
    this.mode = new ClockMode();
    this.transactions = new ClockTransactions();
    this.audio = new ClockAudio();
    this.network = new ClockNetwork();
    this.video = new ClockVideo();
    this.settings = new ClockSettings();
  }
  // tslint:enable:no-use-before-declare

  // tslint:disable:member-ordering
  // displayable fields
  nextSaleText: string;
  auctionName: string;
  // tslint:enable:member-ordering
}

export class NextSale {
  clockName: string;
  description: string;
  startDate: string;
}

export class ClockMode {
  mode: number;
  clockStep: number;
  clockSpeed: number;
  clockStepUp: number;
  clockSpeedUp: number;
  snap: number;
  minimumPriceSteps: number;
  clockStepsAfterMinimumPrice: number;
  lowestStopClockPricePercentage: number;
  startPriceIncrementSteps: number;
  maxRoundSteps: number;
  offsetPriceSteps: number;
  restartSteps: number;
  restartStepSpeed: number;
  waitTimeOnPurchase: number;
  waitTime: number;
  minWaitTime: number;
  maxWaitTime: number;
  waitTimeMinPrice: number;
  minWaitTimeMinPrice: number;
  maxWaitTimeMinPrice: number;
  maxDownstepsToAllowRisingClock: number;
  beepOnStartWaitTime: boolean;
  hideClockCircle: boolean;
  reactivateWaitTime: boolean;
  waitTimePurchaseAfterWaitTImeMinPrice: boolean;
  masterDataListRowId: number;
  showLastCallPopup: boolean;
  showTemporaryWinnerDelay: number;
  biddingModeIncrementSteps: number;
  lotNumberPrefix: number;
  useMultiTransaction: boolean;
  postScanningTime: number;
  validateMaximumNumberOfUsers: number; 
  transactionPriceEqualsTo: number;
  orderingBuyersPriority: number; 

}

export class ClockTransactions {
  showOnlyTransactionsInBuffer: boolean;
  // allowPartialSaleOfGroupedLots: boolean;
  // allowPartialSaleOfSingleLotWithMultipleSaleUnits: boolean;
  partialSaleEntryTimeout: number;
  visibleTransactions: number;
  // enableTransactionBuffer: boolean;
  // transactinoBufferSize: number;
  // allowRemoveTransaction: boolean;
  // allowPriceAdjust: boolean;
  // allowQuantityReduction: boolean;
  buyerMinimumPriceId: number;
  buyerNotSoldId: number;
  // groupedBuffer: boolean;
  showTransactions: number;
  transactionInfoMs: number;
  enableTransactionDeleteRequest; boolean;
}

export class ClockAudio {
  enableAudio: boolean;
  audioAuctioneer: number;
  audioBuyer: boolean;
  audioDisconnectType: number;
}

export class ClockNetwork {
  networkLatencyExcellent: number;
  networkLatencyAverage: number;
  networkLatencyPoor: number;
  networkLatencyTimeout: number;
}

export class ClockSettings {
  maskDotpositionOnBuyIntention: boolean;
  ticketPrinter: boolean;
  creditLimitVerificationActive: boolean;
  buyerNumber: boolean;
  productClock: boolean;
  lotsDivisible: boolean;
  filterMatrixByClockmode: boolean;
  subbuyerValidation: boolean;
  // typeOfSale: number;
  visibleNextLots: number;
  autoValidateBuyerChoice: number;
  autoValidationDelay: number; // current lot update delay (after validation)
  buyerPopupRequestAmount: number;
  livePriceIndications: boolean;
  pauseMessages: Array<string> = [];
  pauseMessage: string;
  autoRestartPartialLot: boolean;
  autoRestartEntireLot: boolean; 
  autoRestartAfterSnap: boolean
  autoRestartLotDelay: number;
  autoRestartAfterSnapDelay: number;
  requestNextLotNumber: boolean;
  lotInPreparationAutoDelay: number;
  temporaryBlockBuyerAmountOfClockstarts: number;
  temporaryBlockBuyerResetOnNextlot: boolean;
  showMinimumPriceDot: boolean;
  removeLotOnSkipPartiallySoldLot: boolean;
  auctioneerAutoLogout: number;
  showClockMode: boolean;
  limitAmountOfClockStopsByAuctioneer: boolean; 
  maxAmountClockStopsByAuctioneer: boolean;
}

export class ClockVideo {
  videoURL: string;
}

export class CoinCodeMode {
  displayValue: string;
  coinCodeMasterDataListRowId: number;
}

export enum AudioAuctioneer {
  NO_AUDIO = 0,
  ALWAYS_BROADCAST = 1,
  BROADCAST_ON_REQUEST = 2
}

export enum AudioBuyer {
  NO_RETURN_AUDIO = 0,
  ALWAYS_AUDIO_ON_PURCHASE = 1
}

export enum ClockAudioSettings {
  AUCTIONEER_BROADCAST_ONLY = 1,
  AUCTIONEER_BUYER_INTERACTION = 2
}

export enum UserType {
  BUYER = 0,
  AUCTIONEER = 1,
  ADMINISTRATOR = 2
}

export enum ShowTransactions {
  TRANSACTIONS_MAIN_BUYER = 0,
  ALL_TRANSACTIONS = 1,
  TRANSACTIONS_OWN = 2
}

export enum BuyerPopupRequestAmount {
  REMAININGAMOUNT = 0,
  MINIMUMAMOUNT = 1
}

export enum Modes {
  DUTCH_AUCTION = 1,
  TRANSACTION_REGISTRATION = 2,
  RISING_CLOCK_UP = 3,
  RISING_CLOCK_DOWN_UP = 4,
  BIDDING_CLOCK_UP = 5,
  BIDDING_CLOCK_DOWN_UP = 6,
}

export enum AudioDisconnectType {
  ON_VALIDATION = 0,
  ON_NEXT_CLOCK_START = 1
}

export enum AutoValidateBuyerChoiceType {
  NONE = 0,
  USING_BUY_POPUP = 1,
  USING_BUY_AMOUNT = 2,
  USING_BUY_POPUP_AMOUNT = 3,
}

export enum StartpriceOrRoundBehaviorType {
  USING_STARTPRICE = 0,
  USING_CLOCKROUND = 1
}

export enum SelectLotModeType {
  CLASSIC = 0, 
  USING_CARRIERS = 1
}

export enum ToggleSalesUnitType {
  NONE = 0,
  DURING_VALIDATION = 1, 
  BEFORE_CLOCK_START = 2, 
  ALWAYS = 3
}

export enum VideoProtocol {
  RTSP = 0,
  SRT = 1
}

export enum TransactionPriceEqualsTo {
  VIRTUAL_CLOCK_PRICE = 0,
  HIGHEST_BIDDING_PRICE = 1
}

export enum OrderingBuyersPriority {
    HIGHEST_PRICE_AMD_FASTEST_BIDDER = 0,
    HIGHEST_REQUESTED_AMOUNT = 1
}
