import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// interfaces
import { ICatalogService } from '../../../shared/interfaces/catalog';

// models
import { ApplicationSettings } from '../../../shared/models/application-settings';
import { Catalog, MoveLot } from '../../../shared/models/catalog';

// services
import { WebApiService } from '../../../shared/services/web-api.service';
import { SupplierPublish } from '../../../shared/models/supplier-publish';

@Injectable()
export class CatalogService implements ICatalogService {

  private apiPath: string;
  private supplierId: number;

  constructor(private appSettings: ApplicationSettings, private webApiService: WebApiService) {
    this.apiPath = this.appSettings.adminApi;
  }

  setId(id: number){
    this.supplierId = id;
  }

  getAuctionCatalogs(auctionId: number): Observable<Array<Catalog>> {
    return this.webApiService.getList(this.apiPath + 'auctioncluster/auction/' + auctionId + '/catalog');
  }

  getAllSupplyAndForecastCatalogs(auctionClusterId: number): Observable<Array<Catalog>> {
    return this.webApiService.getList(this.apiPath + `supplierlevel/${this.supplierId}` + '/auctioncluster/' + auctionClusterId + '/catalog/all');
  }

  getAuctionForecastCatalog(auctionClusterId: number, auctionId: number): Observable<Array<Catalog>> {
    return this.webApiService.getList(this.apiPath + `supplierlevel/${this.supplierId}` + '/auctioncluster/' + auctionClusterId + '/auction/' + auctionId + '/catalog/forecast');
  }

  //ICatalogService

  getAllSupplyCatalogs(supplierId: number): Observable<Array<Catalog>> {
    return this.webApiService.getList(this.apiPath + `supplierlevel/${supplierId}` + '/auctioncluster/auction/catalog');
  }

  getAllSupplyAndForecastCatalogsForReports(auctionClusterId: number): Observable<Array<Catalog>> {
    return this.webApiService.getList(this.apiPath + `supplierlevel/${this.supplierId}` + '/auctioncluster/' + auctionClusterId + '/catalog/all');
  }
    
  getAllForecastCatalogs(auctionClusterId: number): Observable<Array<Catalog>> {
    return this.webApiService.getList(this.apiPath + `supplierlevel/${this.supplierId}` + '/auctioncluster/' + auctionClusterId + '/catalog/forecast');
  }
  
  moveLots(auctionId: number, catalogId: number, destinationCatalogId: number, moveLot: MoveLot): Observable<any> {
    return this.webApiService.editSingle<MoveLot>(this.apiPath + `supplierlevel/${this.supplierId}` + '/auction/'
    + auctionId + '/catalog/' + catalogId + '/movelots/' + destinationCatalogId, moveLot);
  }

  getNumberOfLotsToMove(auctionId: number, catalogId: number, moveLot: MoveLot): Observable<any> {
    return this.webApiService.save(this.apiPath + `supplierlevel/${this.supplierId}` + '/auction/'
    + auctionId + '/catalog/' + catalogId + '/movelots/numberoflots', moveLot);
  }

  setSupplyEditorMode(cookieService: any): boolean {
    return;
  }

  publishSupplierLots(auctionId: number, catalogId: number, suppliers: SupplierPublish): Observable<any> {
    return;
  }
}
